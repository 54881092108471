// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-js": () => import("./../../../src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-suppliers-js": () => import("./../../../src/pages/suppliers.js" /* webpackChunkName: "component---src-pages-suppliers-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-comms-link-js": () => import("./../../../src/templates/commsLink.js" /* webpackChunkName: "component---src-templates-comms-link-js" */),
  "component---src-templates-sub-system-js": () => import("./../../../src/templates/subSystem.js" /* webpackChunkName: "component---src-templates-sub-system-js" */),
  "component---src-templates-system-js": () => import("./../../../src/templates/system.js" /* webpackChunkName: "component---src-templates-system-js" */),
  "component---src-templates-team-member-js": () => import("./../../../src/templates/teamMember.js" /* webpackChunkName: "component---src-templates-team-member-js" */)
}

